<template>
  <v-row class="pt-6">
    <v-col v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </v-col>

    <template v-else>
      <v-col md="3">
        <v-list
          class="pa-0"
          color="transparent"
          dense
          nav>
          <v-list-item
            v-for="({ title, icon, to }, index) of navItems"
            :key="index"
            :to="to">
            <v-list-item-action>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col md="9">
        <router-view v-if="!loading"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProfile',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.profile'))
  },
  header () {
    return {
      avatar: {
        type: 'avatar',
        item: this.userProfile,
      },
      title: `${this.userProfile.given_name} ${this.userProfile.family_name}`,
      description: {
        text: this.$t('routes.profileDescription'),
      },
      metas: [
        this.userProfile.username,
        this.$t('joined', [this.$date.format(this.$date.$getTimestamp(this.userProfile?.created_at), 'MMM d, yyyy')]),
      ],
    }
  },
  data: () => ({
    profile: null,
    loading: true,
  }),
  computed: {
    ...mapState('user', {
      userProfile: (state) => state.profile,
    }),
    navItems () {
      return [{
        title: this.$t('routes.userAccount'),
        icon: 'settings',
        to: { name: 'userAccount' },
      },
      ...(
        this.$showFeature.notifications
          ? [{
              title: this.$t('routes.userWatchRules'),
              icon: 'visibility',
              to: { name: 'userWatchRules' },
            }]
          : []),
      {
        title: this.$t('routes.userEmails'),
        icon: 'mail_outline',
        to: { name: 'userEmails' },
      },
      {
        title: this.$t('routes.userSecurity'),
        icon: 'security',
        to: { name: 'userSecurity' },
      }]
    },
  },
  async mounted () {
    await this.GET_PROFILE()
    this.profile = _.cloneDeep(this.userProfile)
    this.loading = false
  },
  methods: {
    ...mapActions('user', [
      'GET_PROFILE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        title: 'User profile',
        joined: 'Joined on {0}',
      },
      es: {
        title: 'Perfil del usuario',
        joined: 'Se unió a {0}',
      },
      fr: {
        title: 'Profil utilisateur',
        joined: 'Rejoint le {0}',
      },
    },
  },
}
</script>
